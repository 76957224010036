import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

export const HELP_CENTER = {
  HOME: 'help_center',
}

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const { FF_ENABLED_INTERCOM_ROUTE_HELP_CENTER_BY_COUNTRY } =
    useRuntimeConfig().public

  const enableIntercom =
    FF_ENABLED_INTERCOM_ROUTE_HELP_CENTER_BY_COUNTRY?.includes(
      market.countryCode,
    )

  return [
    {
      name: HELP_CENTER.HOME,
      path: 'help',
      component: () => import('./Home.vue'),
      meta: {
        auth: {
          required: false,
        },
        // Required because help-center components are shown/hidden depending on auth state. it prevents UI flashes on full-page reload
        intercom: {
          enableIntercom,
          launcher: true,
        },
        middleware: ['fetch-user'],
        layout: false,
        owners: ['bot-squad-sales-customer-care-front'],
      },
    },
  ]
}
