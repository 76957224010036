import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

import { useIntercomStore } from '@backmarket/nuxt-layer-care-platform/stores/intercom/useIntercomStore'
import { storeToRefs } from 'pinia'

import { DASHBOARD } from '~/scopes/dashboard/routes'

// https://nuxt.com/docs/guide/directory-structure/middleware
export default defineNuxtRouteMiddleware((to) => {
  const storeIntercom = useIntercomStore()
  const { intercom } = storeToRefs(storeIntercom)
  const { boot, initIntercom, shutdown } = storeIntercom

  if (to.meta?.intercom?.enableIntercom) {
    if (to.query.intercom_ticket_id && to.query.notification_link_uid) {
      if (to.name !== DASHBOARD.MY_ORDERS.SELF) {
        return navigateTo({
          name: DASHBOARD.MY_ORDERS.SELF,
          replace: true,
          params: {
            locale: to.params.locale,
          },
          query: {
            intercom_ticket_id: String(to.query.intercom_ticket_id),
          },
        })
      }
    }

    if (import.meta.client && intercom.value.isAvailable) {
      boot()

      return true
    }

    initIntercom({
      hide_default_launcher: !to.meta?.intercom?.launcher,
    })

    return true
  }

  if (import.meta.client && intercom.value.isAvailable) {
    shutdown()

    return true
  }

  return true
})
