import { onMounted, ref } from 'vue'

import type { GetOfferResponseLegacy } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferLegacy'
import { useLocalStorage } from '@backmarket/utils/composables/useLocalStorage'

export function useBuybackFunnelOffer() {
  const offer = ref<GetOfferResponseLegacy | null>(null)
  const localStorage = useLocalStorage()

  function resetBuyBackOffer() {
    offer.value = null
    localStorage.removeItem('buyback_offer')
  }

  function setBuyBackOffer(
    newOffer: GetOfferResponseLegacy,
    shouldStoreLocally = true,
  ) {
    offer.value = newOffer
    if (shouldStoreLocally) {
      localStorage.setItem('buyback_offer', JSON.stringify(offer.value))
    }
  }

  onMounted(() => {
    const storedOffer = localStorage.getItem('buyback_offer')

    offer.value = storedOffer ? JSON.parse(storedOffer) : null
  })

  return {
    offer,
    resetBuyBackOffer,
    setBuyBackOffer,
  }
}
